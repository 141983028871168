import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Hidden, Box, Paper, Typography, Grid, Container, Button, Snackbar, SnackbarContent, IconButton, List, ListItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image


import styled from "styled-components";

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Bline = styled.hr`
width: 70px;
border-color: #1c6a9c;
display:inline-block;
`


export default function Careers(){

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "homebg.jpg" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1050
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
    
          }
        `
      )
    
      const image = getImage(placeholderImage);
      
      // Use like this:
      const bgImage = convertToBgImage(image);

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [experience, setExperience] = React.useState('');
  const [letter, setLetter] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [result, setResult] = React.useState('');
  const [submitname, setSubmitname] = React.useState('Submit');


  const handleChange = (event) =>{
  const target = event.target;
  const value = target.value;
  const name = target.name;
  if(name==="name")
  {
    setName(value);
  }
  if(name==="email")
  {
    setEmail(value);
  }
  
  if(name==="experience")
  {
    setExperience(value);
  }

  if(name==="letter")
  {
    setLetter(value);
  }

  if(name==="mobile")
  {
    setMobile(value);
  }
  
}


const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
   setOpen(false);
};
const handleSubmit = (event) => {
  event.preventDefault();
  setSubmitname('Submitting... ')
  let collection={};
    collection.name = name;
    collection.email = email;
    collection.mobile = mobile;
    collection.experience = experience;
    collection.letter = letter;
    collection.scode = "sdferderv3876MBg";

 fetch('https://apiv2.chennaihost.com/site/career', {
      method: "post",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(collection),
      })
      .then( (response) => { 
        return response.json();
      })
      .then((data) => {
        if(data)
        {
          setResult(data.result);
          setName('');
          setEmail('');
          setExperience('');
          setLetter('');
          setMobile('');
          setOpen(true);
          setSubmitname('submit');
       
        }
     }
      )
 


};

return(
  <Layout>
    <Seo title="Careers - Latest Jop Openings" description="Latest Jop openings from Africorp Healthscare" />
    <ThemeProvider theme={theme}>
    <Aboutback title="Careers" />

    
    <Container>
     <Box pt={5} pb={5}>
       <Grid container>
         <Grid item xs={12}>
           <Typography variant="h5" component="h2">
           <b>Job Title:</b> SALES EXECUTIVE – HEALTHCARE
           </Typography>
           <Bline/>

           <Typography variant="body1" paragraph>
           This position will be responsible to achieve the sales assigned for Medical Equipment specialties like Radiology Products (X-Ray, Ultrasound, Color Doppler, CT Scan), Critical Care Products (Patient Monitors, Ventilators, Anaesthesia & Operation Theater) & Other Hospital equipments.
           </Typography>
           <Typography variant="h6">
           <b>Desired Qualification & Experience:</b>
           </Typography>
           <Bline/>
           <Typography variant="body1" paragraph>
           Diploma / B.E / B.Tech / B.Sc.  in Medical Electronics / Biomedical Engineering / Instrumentation / Electronics / Nursing / Radiographer
          Minimum of 1 – 3 years of relevant / other sales experience.
         </Typography>

         <Typography variant="h6">
           <b>Job Responsibilities:</b>
           </Typography>
           <Bline/>

<List>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>To promote the assigned products Sales activity, identifying and developing new business opportunities – Result Oriented.</Typography></ListItem>
    <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>To generate leads & converting leads into sales follow up on prospective customers, provide product information and / or   organize demonstrations to generate and maintain interest among the clients, and the prospects.</Typography></ListItem>
    <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>TWO- WHEELER driving a must. Flexible to work beyond the working hours with extensive traveling to different locations.</Typography></ListItem>
    <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>To work as a Team Player to provide efficient service delivery to all accounts within assigned area along with maintaining highest level of Company Integrity.</Typography></ListItem>
    <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>To work closely with Customers, Clinical Technicians, Doctors, Hospital Administration, Bio-Medicals and Hospital Management.</Typography></ListItem>
    <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>To prepare Daily Call Reports, update Attendance, capture Product Complaints, capture Opportunities.</Typography></ListItem>
    <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Ability to work independently with Good interpersonal skills ,Self-starter and Self-motivated with Good communication, both verbally and in writing</Typography></ListItem>
    </List>

   <Box pt={1}>
    <Typography variant="body1" paragraph>
    Suitable interested candidates please send your updated CV to <a href="https://www.srkitsolutions.com/" rel="noopener noreferrer" target="_blank">info@africorp-healthcare.com</a> immediately for further discussions
    </Typography>
    </Box>

         </Grid>
       </Grid>
       </Box>
       </Container>

       <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ backgroundAttachment:'fixed' }}
>
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
   <Container>
     <Box pt={4} pb={5}>

     <Grid container alignItems="center" justify="center">
     <Grid item xs={12} sm={10} md={8}>
         <Paper elevation={3}>
           <Box p={4}>
          
       <Typography variant="h5">
            Submit your Job Application
             </Typography>
<Bline/>
<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{display: 'flex', alignItems: 'center',}}>
           {result}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon style={{fontSize: 20,}} />
        </IconButton>,
      ]}
     
    />
        </Snackbar>
<ValidatorForm onSubmit={handleSubmit}  autoComplete="off">
<TextValidator 
          id="name"
          name="name"
          label="Your Name" 
          value={name}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

<TextValidator 
          id="mobile"
          name="mobile"
          label="Mobile" 
          value={mobile}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="email"
          name="email"
          label="Email" 
          value={email}
          onChange={handleChange}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'email is not valid']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="experience"
          name="experience"
          label="Experience" 
          value={experience}
          onChange={handleChange}
          multiline
          rows="4"
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

<TextValidator 
          id="letter"
          name="letter"
          label="Covering Letter" 
          value={letter}
          onChange={handleChange}
          multiline
          rows="4"
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />
          
           

        <Button variant="contained" color='primary' size="large" type="submit">{submitname}</Button>
         </ValidatorForm>
  

    </Box>
    </Paper>

       </Grid>
     </Grid>
     </Box>
   </Container>
   </div>
   </BackgroundImage>

   <Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>

    </ThemeProvider>
  </Layout>
);
}


